exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-series-tsx": () => import("./../../../src/pages/series.tsx" /* webpackChunkName: "component---src-pages-series-tsx" */),
  "component---src-pages-templates-news-template-tsx": () => import("./../../../src/pages/templates/newsTemplate.tsx" /* webpackChunkName: "component---src-pages-templates-news-template-tsx" */),
  "component---src-pages-templates-series-template-tsx": () => import("./../../../src/pages/templates/seriesTemplate.tsx" /* webpackChunkName: "component---src-pages-templates-series-template-tsx" */),
  "component---src-pages-templates-volume-template-tsx": () => import("./../../../src/pages/templates/volumeTemplate.tsx" /* webpackChunkName: "component---src-pages-templates-volume-template-tsx" */)
}

