module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-89f7d29643/2/.yarn/berry/cache/gatsby-plugin-manifest-npm-5.13.0-d134c631ce-10c0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0f87a6c2d369bd06ede87fca53b96fa2"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-fb6839101a/2/.yarn/berry/cache/gatsby-plugin-styled-components-npm-6.13.0-768ccceafe-10c0.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-284492b71e/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
